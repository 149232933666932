import { AccountId, Amazonian, Category, Svc } from "API";
import { addDays, addHours, addMinutes, addMonths, addSeconds, addWeeks, addYears, } from "date-fns";

// Build SFDC Opp Link
export const buildOppLink = (oppId: string) => {
  return `https://aws-crm.lightning.force.com/lightning/r/Opportunity/${oppId}/view`;
};

export const buildAccountLink = (oppId: string) => {
  return `https://aws-crm.lightning.force.com/lightning/r/Account/${oppId}/view`;
};

export const isValidYearMonth = (str: string) => {
  const reg = /^([12][0-9]{3})-(0[1-9]|1[0-2])$/;
  return reg.test(str);
};

export const isAPostiveNumber = (str: string | number) => {
  if (typeof str === "number") {
    return Number.isInteger(str) && str > 0;
  } else {
    if (str.indexOf(".") >= 0) {
      return false;
    } else {
      var n = Math.floor(Number(str));
      return n !== Infinity && n > 0;
    }
  }
};

export const getBLen = (str: any) => {
  if (str == null) return 0;
  if (typeof str != "string") {
    str += "";
  }
  // eslint-disable-next-line no-control-regex
  return str.replace(/[^\x00-\xff]/g, "01").length;
};

export const isValidOppId = (oppId: string): boolean => {
  const REG = /^[a-zA-Z0-9]{18}$/;
  return REG.test(oppId);
};

export const isValidAccountId = (accountId: string): boolean => {
  const REG = /^[a-zA-Z0-9]{15,18}$/;
  return REG.test(accountId);
};

export const isValidUrl = (url: string): boolean => {
  const REG = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;
  return REG.test(url);
};

export const handleNullValue = (value: any) => {
  if (value) {
    return value;
  }
  return "-";
};

export const humanFileSize = (bytes: any, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
      );

  return bytes.toFixed(dp) + " " + units[u];
};

export const converAmazonianToFilter = (list: any, propKey: string) => {
  const propsList: any = [];
  list.forEach((element: Amazonian) => {
    propsList.push({
      propertyKey: propKey,
      value: `${element.name}(${element.userId})`,
    });
  });
  return propsList;
};

export const converSvcToFilter = (list: any, propKey: string) => {
  const propsList: any = [];
  list.forEach((element: Svc) => {
    propsList.push({
      propertyKey: propKey,
      value: `${element.svcName}(${element.svcId})`,
    });
  });
  return propsList;
}

export const converCategoryToFilter = (list: any, propKey: string) => {
  const propsList: any = [];
  list.forEach((element: Category) => {
    propsList.push({
      propertyKey: propKey,
      value: `${element.categoryName}(${element.categoryId})`,
    });
  });
  return propsList;
}

export const convertAccountIdToFilter = (list: any, propKey: string) => {
  const propsList: any = [];
  list.forEach((element: AccountId) => {
    propsList.push({
      propertyKey: propKey,
      value: `${element.sfdcAccountId}`,
    });
  });
  return propsList;
}

export const lastDayInYearAndMonth = function (y: number, m: number) {
  return new Date(y, m, 0).getDate();
};

export const differenceInDays = (dateOne: any, dateTwo: any) => {
  const milliseconds: any = Math.abs(
      (new Date(dateTwo) as any) - (new Date(dateOne) as any)
  );
  const days = Math.ceil(milliseconds / (1000 * 60 * 60 * 24));
  return days;
};

export const lengthInDays = (unit: any, amount: any) => {
  switch (unit) {
    case "second":
      return amount / (24 * 60 * 60);
    case "minute":
      return amount / (24 * 60);
    case "hour":
      return amount / 24;
    case "day":
      return amount;
    case "week":
      return amount * 7;
    case "month":
      return amount * 30;
    case "year":
      return amount * 365;
  }
};

export const isValidRangeFunction: any = (range: any) => {
  if (range?.type === "absolute") {
    const [startDateWithoutTime] = range.startDate.split("T");
    const [endDateWithoutTime] = range.endDate.split("T");

    if (!startDateWithoutTime || !endDateWithoutTime) {
      return {
        valid: false,
        errorMessage:
            "The selected date range is incomplete. Select a start and end date for the date range.",
      };
    }

    if (differenceInDays(range.startDate, range.endDate) >= 366) {
      return {
        valid: false,
        errorMessage:
            "The selected date range is too large. Select a range up to one year.",
      };
    }

    if (differenceInDays(range.startDate, range.endDate) < 1) {
      return {
        valid: false,
        errorMessage:
            "The selected date range is too small. Select a range larger than one day.",
      };
    }
  } else if (range?.type === "relative") {
    if (isNaN(range.amount)) {
      return {
        valid: false,
        errorMessage:
            "The selected date range is incomplete. Specify a duration for the date range.",
      };
    }

    if (lengthInDays(range.unit, range.amount) < 1) {
      return {
        valid: false,
        errorMessage:
            "The selected date range is too small. Select a range larger than one day.",
      };
    }

    if (lengthInDays(range.unit, range.amount) >= 366) {
      return {
        valid: false,
        errorMessage:
            "The selected date range is too large. Select a range up to one year.",
      };
    }
  }
  return {valid: true};
};

export const convertToAbsoluteRange = (range: any) => {
  if (range.type === "absolute") {
    return {
      start: new Date(range.startDate),
      end: new Date(range.endDate),
    };
  } else {
    const now = new Date();
    const start = (() => {
      switch (range.unit) {
        case "second":
          return addSeconds(now, -range.amount);
        case "minute":
          return addMinutes(now, -range.amount);
        case "hour":
          return addHours(now, -range.amount);
        case "day":
          return addDays(now, -range.amount);
        case "week":
          return addWeeks(now, -range.amount);
        case "month":
          return addMonths(now, -range.amount);
        case "year":
          return addYears(now, -range.amount);
      }
    })();
    return {
      start: start,
      end: now,
    };
  }
};
