import React, { useCallback, useContext, useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { appSyncRequest } from "assets/js/request";
import { getUseCaseById } from "graphql/queries";

import {
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  Spinner,
} from "@amzn/awsui-components-react/polaris";

import ValueWithLabel from "common/ValueWithLabel";

import { SubmitMode, UseCaseAbstract, UserRole } from "API";
import { PHONE_TOOL_LINK, USECASE_NOT_FOUNT_ID } from "assets/js/const";
import { handleNullValue, } from "assets/js/utils";
import AppContext from "context/AppContext";
import { updateUseCaseStatus } from '../../graphql/mutations';
import { Alert, Box, Checkbox, Modal } from '@amzn/awsui-components-react';
import UseCaseStatus from '../comps/UseCaseStatus';
import Tabs from '@amzn/awsui-components-react/polaris/tabs'
import OverView from './case/OverView'
import EngagedCustomer from './case/EngagedCustomer'

interface DetailContentProps {
  tabId: string;
  useCaseId: string;
  pageType?: string;
}

const DetailCompUseCase: React.FC<DetailContentProps> = (
    props: DetailContentProps
): JSX.Element => {
  const { tabId, useCaseId } = props;
  const appConfig = useContext(AppContext);
  const history = useHistory();
  const [showConfirm, setShowConfirm] = useState(false);
  const [acknowledge, setAcknowledge] = useState(false);
  const [isAcknowledge, setIsAcknowledge] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [curUseCase, setCurUseCase] = useState<UseCaseAbstract>();

  const [activeTabId, setActiveTabId] = useState(tabId || "overview");

  const getUseCaseInfoById = async () => {
    try {
      setLoadingData(true);
      const useCaseData: any = await appSyncRequest(getUseCaseById, {useCaseId: useCaseId});
      const useCaseInfo: UseCaseAbstract = useCaseData?.data?.getUseCaseById;
      if (useCaseInfo.useCaseId === USECASE_NOT_FOUNT_ID) {
        history.push({
          pathname: "/error/not-found",
        });
      } else {
        console.info("useCaseInfo:", useCaseInfo);
        if (useCaseData?.data?.getUseCaseById) {
          const useCaseDataObj = useCaseData.data.getUseCaseById;
          useCaseDataObj.useCaseName = decodeURIComponent(
              useCaseDataObj.useCaseName.replace(/\+/g, " ") || ""
          );
          useCaseDataObj.useCaseDesc = decodeURIComponent(
              useCaseDataObj.useCaseDesc.replace(/\+/g, " ")
          );
          useCaseDataObj.architectDesc = decodeURIComponent(
              useCaseDataObj.architectDesc.replace(/\+/g, " ")
          );
          useCaseDataObj.architectDiagrams.forEach((element: any) => {
            element.architectFilename = decodeURIComponent(
                element.architectFilename.replace(/\+/g, " ")
            );
          });
          useCaseDataObj.attachments.forEach((element: any) => {
            element.attachmentFilename = decodeURIComponent(
                element.attachmentFilename.replace(/\+/g, " ")
            );
            element.attachmentDesc = decodeURIComponent(
                element.attachmentDesc.replace(/\+/g, " ")
            );
          });
          setCurUseCase(useCaseData.data.getUseCaseById);
          console.info("curUseCase:", curUseCase);
        }
      }
      setLoadingData(false);
    } catch (error) {
      console.error(error);
    }
  };

  const downloadFile = async (fileKey: string) => {
    // Creates download url that expires in 5 minutes/ 300 seconds
    const downloadUrl = await Storage.get(fileKey, {expires: 600});
    window.open(downloadUrl.toString(), "_blank");
  };

  const abandonUseCaseById = useCallback(async () => {
    try {
      const abandonResult: any = await appSyncRequest(updateUseCaseStatus, {
        input: {
          useCaseId: useCaseId,
          useCaseStatus: "Abandon",
        }
      });
      console.info("abandonResult:", abandonResult);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }, [useCaseId]);
  //
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getUseCaseInfoById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToCustomer = () => {
    history.push({
      pathname: `/usecase-all/${curUseCase?.useCaseId}/${curUseCase?.workload?.map((element) => element?.groupId)[0]}/customer`,
    });
  };

  return (
      <div>
        <div className="mb-10">
          <Header
              variant="h1"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {appConfig.userId === curUseCase?.contributor?.userId &&
                      curUseCase?.useCaseStatus !== SubmitMode.Abandon && (
                          <Button
                              onClick={() => {
                                history.push({
                                  pathname: "/usecase/edit/" + useCaseId,
                                });
                              }}
                          >
                            Edit
                          </Button>
                      )}
                  {appConfig.userId === curUseCase?.contributor?.userId &&
                      curUseCase?.useCaseStatus !== SubmitMode.Abandon && (
                          <Button
                              onClick={() => {
                                setShowConfirm(true);
                              }}
                              variant="primary"
                          >
                            Abandon
                          </Button>
                      )}
                  {(appConfig.userRole === UserRole.Contributor ||
                      appConfig.userRole === UserRole.Manager ||
                      appConfig.userRole === UserRole.Reviewer) && (
                      <Button
                          onClick={() => {
                            goToCustomer();
                          }}
                          variant="primary"
                      >
                        Customer Engage
                      </Button>
                  )}
                </SpaceBetween>

              }
          >
            {useCaseId.toUpperCase()}
            <Modal
                onDismiss={(event) => {
                  if (event.detail.reason === "closeButton") {
                    setShowConfirm(false);
                  }
                  console.info("AAA:", event.detail.reason);
                }}
                visible={showConfirm}
                closeAriaLabel="Close modal"
                footer={
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                          variant="link"
                          onClick={() => {
                            setShowConfirm(false);
                          }}
                      >
                        Cancel
                      </Button>
                      <Button
                          variant="primary"
                          onClick={() => {
                            if (!isAcknowledge) {
                              setShowError(true);
                            } else {
                              //TODO
                              abandonUseCaseById();
                              setShowConfirm(false);
                            }
                          }}
                      >
                        Abandon
                      </Button>
                    </SpaceBetween>
                  </Box>
                }
                header="Abandon Use Case"
            >
              <Alert visible={true} header="Abandon Use Case">
                <div>
                  If the Use Case is abandoned, only Contributor ownself can see it and cannot edit it anymore.
                </div>
                <div className="mt-20">
                  <Checkbox
                      onChange={({detail}) => {
                        setAcknowledge(detail.checked);
                        setIsAcknowledge(detail.checked);
                        setShowError(false);
                      }}
                      checked={acknowledge}
                  >
                    <b>
                      I acknowledge the Use Case can abandon.
                    </b>
                  </Checkbox>
                </div>
              </Alert>
              <Alert className="mt-10" visible={showError} type="error">
                Please acknowledge all checkboxes before proceeding.
              </Alert>
            </Modal>
          </Header>
        </div>

        {loadingData ? (
            <Spinner/>
        ) : (
            <div>
              <Container header={<Header variant="h2">Use Case Detail</Header>}>
                <div>
                  <div>
                    <ColumnLayout columns={3} variant="text-grid">
                      <SpaceBetween size="l">
                        <ValueWithLabel label="Use Case Name">
                          {handleNullValue(curUseCase?.useCaseName)}
                        </ValueWithLabel>
                        <ValueWithLabel label="Team">
                          {handleNullValue(curUseCase?.contributor?.teamName)}
                        </ValueWithLabel>
                        {curUseCase?.contributor?.userId === appConfig.userId && (
                            <ValueWithLabel label="Use Case Status">
                              <div>
                                <UseCaseStatus UseCaseStatus={curUseCase?.useCaseStatus}/>
                              </div>
                            </ValueWithLabel>
                        )}
                      </SpaceBetween>
                      <SpaceBetween size="l">
                        <ValueWithLabel label="WWSO GTM">
                          {curUseCase?.contributor?.name} (
                          <Link
                              href={`${PHONE_TOOL_LINK}/${curUseCase?.contributor?.userId}`}
                              external={true}
                          >
                            {handleNullValue(curUseCase?.contributor?.userId)}
                          </Link>
                          )
                        </ValueWithLabel>
                        <ValueWithLabel label="Supervisor">
                          {curUseCase?.contributor?.supervisorId} (
                          <Link
                              href={`${PHONE_TOOL_LINK}/${curUseCase?.contributor?.supervisorId}`}
                              external={true}
                          >
                            {curUseCase?.contributor?.supervisorId}
                          </Link>
                          )
                        </ValueWithLabel>
                      </SpaceBetween>
                      <SpaceBetween size="l">
                        <ValueWithLabel label="Manager">
                          {curUseCase?.contributor?.managerName} (
                          <Link
                              href={`${PHONE_TOOL_LINK}/${curUseCase?.contributor?.managerId}`}
                              external={true}
                          >
                            {curUseCase?.contributor?.managerId}
                          </Link>
                          )
                        </ValueWithLabel>
                        <ValueWithLabel label="Create Time">
                          {curUseCase?.createDateTime || "-"}
                        </ValueWithLabel>
                      </SpaceBetween>
                    </ColumnLayout>
                  </div>
                </div>
              </Container>
              <div className="mt-10">
                <Tabs
                    onChange={(event) => {
                      console.info("event.detail.activeTabId:", event.detail.activeTabId);
                      setActiveTabId(event.detail.activeTabId);
                    }}
                    activeTabId={activeTabId}
                    tabs={[
                      {
                        label: "Overview",
                        id: "overview",
                        content: (
                            <OverView
                                loading={loadingData}
                                curUseCase={curUseCase}
                                useCaseId={useCaseId}
                            />
                        ),
                      },
                      {
                        label: "Engaged Results",
                        id: "result",
                        content: (
                            <EngagedCustomer
                                useCaseId={useCaseId}
                            />
                        ),
                      },
                    ]}
                />
              </div>
            </div>
        )}
      </div>
  );
};

export default DetailCompUseCase;
